<template>
  <div class="main-wrap">
    <!-- 导航栏 -->
    <!-- 表单查询 -->
    <el-form :inline="true" :model="queryForm" class="demo-form-inline">
      <el-form-item>
        <el-input
          v-model="queryForm.landingPageName"
          placeholder="请输入落地页名称"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <!-- <el-select
          v-model="queryForm.groupName"
          placeholder="请选择分组"
          clearable
          filterable
        >
          <el-option
            v-for="item in channelOptions"
            :key="item.id"
            :label="item.channelName"
            :value="item.id"
          >
          </el-option>
        </el-select> -->
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryData">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="openAddPage">添加落地页</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="openAddGroup"
          >添加落地页分组</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 数据表 -->
    <el-table
      v-loading="loading"
      border
      stripe
      :data="tableData"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      height="1000"
    >
      <el-table-column
        prop="id"
        label="ID"
        align="center"
        width="80"
      ></el-table-column>
      <el-table-column
        prop="landingPageName"
        label="落地页名称"
        min-width="160"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="groupName"
        label="落地页分组"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="templateId"
        label="模板"
        min-width="120"
        align="center"
      >
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.templateId"
            filterable
            @change="changeTemplate(scope.row)"
          >
            <el-option
              v-for="(item, key) in tempTemplateMap"
              :key="key"
              :value="key"
              :label="item"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="当前版本"
        min-width="120"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            :type="scope.row.status ? '' : 'primary'"
            @click="changeTemplateStatus(scope.row)"
            >{{ statusMap[scope.row.status] }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="carouselModeName"
        label="是否轮换"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="pageViewNums"
        label="PV"
        min-width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="conversionAmount"
        label="转化量"
        min-width="160"
        align="center"
      ></el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        min-width="160"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            class="el-icon-edit"
            type="text"
            @click="openEdit(scope.row.id)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            class="el-icon-document-copy"
            type="text"
            @click="cloneEdit(scope.row.id)"
            >复制</el-button
          >
          <el-button
            size="mini"
            class="el-icon-delete"
            type="text"
            @click="deleteThis(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="payableRetBalance"
        label="访问"
        min-width="160"
        align="center"
      >
        <template slot-scope="scope">
          <el-tag size="small" @click="getLandingInfo(scope.row)">预览</el-tag>
          <el-tag
            size="small"
            style="margin: 0 10px"
            @click="getLandingLink(scope.row)"
            >复制</el-tag
          >
          <el-tag size="small" @click="goDetail(scope.row)">页面数据</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加 -->
    <el-dialog
      title="添加落地页"
      :visible.sync="dialogAdd"
      :close-on-click-modal="false"
      top="3%"
      width="55%"
      :before-close="clearAddForm"
    >
      <el-form
        ref="dictAddForm"
        :model="dictAddForm"
        :rules="dictAddRules"
        status-icon
        size="medium"
        label-width="120px"
      >
        <el-form-item label="落地页名称" prop="landingPageName">
          <el-input
            v-model="dictAddForm.landingPageName"
            placeholder="请输入落地页名称"
            clearable
            style="width: 220px"
          ></el-input>
        </el-form-item>
        <el-form-item label="选择模板" prop="templateId">
          <el-select
            v-model="dictAddForm.templateId"
            placeholder="请选择"
            clearable
            filterable
            style="width: 220px"
          >
            <el-option
              v-for="item in templateMap"
              :key="item.id"
              :label="item.templateName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label=" 上传图片" prop="fileUrl">
          <div class="imgrow flex">
            <div v-if="dictAddForm.fileUrl !== ''" class="imgbox">
              <el-image :src="dictAddForm.fileUrl" class="upload-img">
              </el-image>
              <div class="img-action">
                <i class="el-icon-close delete-btn" @click="deleteThisImg"></i>
              </div>
            </div>
            <el-upload
              v-else
              class="img-uploader item"
              action="#"
              :show-file-list="false"
              :http-request="upload"
            >
              <i class="el-icon-plus img-uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item> -->
        <el-form-item label="轮换切换" prop="rotation">
          <el-tabs
            v-model="dictAddForm.rotation"
            type="border-card"
            @tab-click="handleAddClick"
          >
            <el-tab-pane label="不轮换" name="1">
              <el-form-item label="默认微信号码">
                <el-select
                  v-model="dictAddForm.wechatNumber"
                  filterable
                  remote
                  :remote-method="getWechatMap"
                  placeholder="请输入微信号"
                  style="width: 220px"
                  clearable
                >
                  <el-option
                    v-for="item in wechatMap"
                    :key="item.id"
                    :label="item.wechatNumber"
                    :value="item.wechatNumber"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane label="按时间" name="2">
              <el-input-number
                v-model.number="dictAddForm.rotationParam"
                :precision="0"
                :step="1"
                :min="1"
                :max="999"
              ></el-input-number
              >（分钟）
              <div v-if="uploadCheckBox" class="checkbox-content">
                <el-checkbox
                  v-for="(item, index) in addWechatGroupMap"
                  :key="index"
                  :label="item.id"
                  :checked="item.ifChecked"
                  @change="changeWechatGroup(index)"
                  >{{ item.groupName }}</el-checkbox
                >
              </div>
            </el-tab-pane>
            <el-tab-pane label="按PV" name="3">
              <el-input-number
                v-model.number="dictAddForm.rotationParam"
                :precision="0"
                :step="1"
                :min="1"
                :max="999"
              ></el-input-number
              >（次）
              <div v-if="uploadCheckBox" class="checkbox-content">
                <el-checkbox
                  v-for="(item, index) in addWechatGroupMap"
                  :key="index"
                  :label="item.id"
                  :checked="item.ifChecked"
                  @change="changeWechatGroup(index)"
                  >{{ item.groupName }}</el-checkbox
                >
              </div>
            </el-tab-pane>
            <el-tab-pane label="按转换量" name="4">
              <el-input-number
                v-model.number="dictAddForm.rotationParam"
                :precision="0"
                :step="1"
                :min="1"
                :max="999"
              ></el-input-number
              >（次）
              <div v-if="uploadCheckBox" class="checkbox-content">
                <el-checkbox
                  v-for="(item, index) in addWechatGroupMap"
                  :key="index"
                  :label="item.id"
                  :checked="item.ifChecked"
                  @change="changeWechatGroup(index)"
                  >{{ item.groupName }}</el-checkbox
                >
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="clearAddForm">取消</el-button>
        <el-button type="primary" @click="addData">确定</el-button>
      </div>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      :title="dictEditForm.landingPageName"
      :visible.sync="dialogEdit"
      :close-on-click-modal="false"
      top="3%"
      width="55%"
      :before-close="clearLandingForm"
    >
      <el-form
        ref="dictEditForm"
        :model="dictEditForm"
        :rules="dictEditRules"
        size="medium"
        :inline="true"
        label-position="right"
      >
        <h3>轮换切换</h3>
        <el-tabs
          v-model="dictEditForm.amCarouselMode.typeStr"
          type="border-card"
        >
          <el-tab-pane label="不轮换" name="1">
            <el-form-item label="默认微信号码">
              <el-select
                v-model="dictEditForm.amCarouselMode.modeWechat"
                filterable
                remote
                :remote-method="editGetWechatMap"
                placeholder="请输入微信号"
                style="width: 220px"
                clearable
              >
                <el-option
                  v-for="item in wechatMap"
                  :key="item.id"
                  :label="item.wechatNumber"
                  :value="item.wechatNumber"
                >
                </el-option>
              </el-select>
              <!-- <el-button
                  type="primary"
                  style="margin-left: 20px"
                  @click="wechatNumberSave"
                  >保存</el-button
                > -->
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="按时间" name="2">
            <el-input-number
              v-model.number="dictEditForm.amCarouselMode.num"
              :precision="0"
              :step="1"
              :min="1"
              :max="999"
            ></el-input-number
            >（分钟）
            <!-- <el-button type="primary" @click="wechatRotationSave"
                >保存轮换参数</el-button
              > -->
            <div v-if="dialogEdit" class="checkbox-content">
              <el-checkbox
                v-for="(item, index) in dictEditForm.weChatGroups"
                :key="index"
                :checked="item.ifChecked"
                @change="changeCheckbox(item.id)"
                >{{ item.groupName }}</el-checkbox
              >
            </div>
          </el-tab-pane>
          <el-tab-pane label="按PV" name="3">
            <el-input-number
              v-model.number="dictEditForm.amCarouselMode.num"
              :precision="0"
              :step="1"
              :min="1"
              :max="999"
            ></el-input-number
            >（次）
            <!-- <el-button type="primary" @click="wechatRotationSave"
                >保存轮换参数</el-button
              > -->
            <div v-if="dialogEdit" class="checkbox-content">
              <el-checkbox
                v-for="(item, index) in dictEditForm.weChatGroups"
                :key="index"
                :checked="item.ifChecked"
                @change="changeCheckbox(item.id)"
                >{{ item.groupName }}</el-checkbox
              >
            </div>
          </el-tab-pane>
          <el-tab-pane label="按转换量" name="4">
            <el-input-number
              v-model.number="dictEditForm.amCarouselMode.num"
              :precision="0"
              :step="1"
              :min="1"
              :max="999"
            ></el-input-number
            >（次）
            <!-- <el-button type="primary" @click="wechatRotationSave"
                >保存轮换参数</el-button
              > -->
            <div v-if="dialogEdit" class="checkbox-content">
              <el-checkbox
                v-for="(item, index) in dictEditForm.weChatGroups"
                :key="index"
                :checked="item.ifChecked"
                @change="changeCheckbox(item.id)"
                >{{ item.groupName }}</el-checkbox
              >
            </div>
          </el-tab-pane>
        </el-tabs>
        <h3>快速设置</h3>
        <div class="fast-set-up">
          <!-- <el-form-item label="基础代码" prop="basicCode">
            <el-input
              v-model="dictEditForm.basicCode"
              placeholder="请输入"
              style="width: 220px"
              clearable
            ></el-input>
          </el-form-item> -->
          <el-form-item label="转化代码" prop="conversionCode">
            <el-input
              v-model="dictEditForm.conversionCode"
              placeholder="请输入"
              style="width: 220px"
              clearable
            ></el-input>
          </el-form-item>
          <!-- <el-button type="primary" @click="fastsetupSave">保存</el-button> -->
        </div>
        <h3>基本设置</h3>
        <div class="base-set-up">
          <el-form-item label="名称" prop="landingPageName">
            <el-input
              v-model="dictEditForm.landingPageName"
              placeholder="请输入"
              style="width: 220px"
              maxlength="32"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="dictEditForm.remark"
              placeholder="请输入"
              style="width: 220px"
              maxlength="32"
              clearable
            ></el-input>
          </el-form-item>
          <!-- <el-button type="primary" @click="fastsetupSave">保存</el-button> -->
        </div>
        <el-form-item label="落地页分组" prop="groupId">
          <el-select
            v-model="dictEditForm.groupId"
            placeholder="请选择分组"
            style="width: 220px"
          >
            <el-option
              v-for="item in landingPageGroups"
              :key="item.id"
              :label="item.groupName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clearLandingForm">取消</el-button>
        <el-button type="primary" @click="sumbitEdit">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="添加"
      :visible.sync="dialogAddGroup"
      :close-on-click-modal="false"
      :before-close="cleardialogAddGroup"
      top="3%"
      width="35%"
    >
      <el-form
        ref="groupAddForm"
        :model="groupAddForm"
        label-width="80px"
        :rules="groupAddFormRules"
        status-icon
        size="medium"
      >
        <el-form-item label="分组名称" prop="groupName">
          <el-input v-model="groupAddForm.groupName"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="groupAddForm.remark" type="textarea"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="cleardialogAddGroup">取消</el-button>
          <el-button type="primary" @click="addGroup">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 分页 -->
    <el-pagination
      background
      :current-page.sync="queryForm.pageNo"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size.sync="queryForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="queryData"
      @current-change="queryData"
      @prev-click="queryData"
      @next-click="queryData"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: "AddDeclare",
  data() {
    const valToRotation = (rule, value, callback) => {
      if (value === "" || value === nul) {
        callback(new Error("请输入"));
      } else if (this.dictAddForm.rotationParam) {
        callback(new Error("请输入"));
      } else {
        callback;
      }
    };
    return {
      groupIds: [],
      queryForm: {
        groupName: null,
        pageView: null,
        pageNo: 1,
        pageSize: 10,
      },
      tableData: [],
      loading: false,
      total: 0,
      dialogAdd: false,
      dictAddRules: {
        landingPageName: [
          { required: true, message: "请输入落地页名称", trigger: "blur" },
        ],
        templateId: [
          { required: true, message: "请选择模板", trigger: "blur" },
        ],
        // fileUrl: [{ required: true, message: "请上传", trigger: "blur" }],
        rotationParam: [{ required: true, message: "请输入", trigger: "blur" }],
        weChatGroupIdList: [
          { required: true, message: "请选择分组", trigger: "blur" },
        ],
        rotation: [
          { required: true, message: "请选择微信分组", trigger: "blur" },
        ],
      },
      dialogAddGroup: false,
      dictAddForm: {
        rotation: "1",
        templateId: 1,
        rotationParam: 0,
        weChatGroupIdList: [],
        wechatNumber: "",
        landingPageName: "",
      },
      dialogEdit: false,
      dictEditRules: {
        conversionCode: [
          { required: true, message: "请输入转化代码", trigger: "blur" },
        ],
        // basicCode: [
        //   { required: true, message: "请输入基础代码", trigger: "blur" },
        // ],
        landingPageName: [
          { required: true, message: "请输入名称", trigger: "blur" },
        ],
        remark: [{ message: "请输入备注", trigger: "blur" }],
        groupId: [{ required: true, message: "请选择分组", trigger: "blur" }],
        rotation: [
          { required: true, message: "请选择微信分组", trigger: "blur" },
        ],
      },
      dictEditForm: {
        rotation: "1",
        fileUrl: "",
        templateId: 1,
        rotationParam: 0,
        conversionCode: "",
        basicCode: "",
        landingPageName: "",
        remark: "",
        groupId: "",
        amCarouselMode: {
          id: "",
          num: "",
          type: "",
          wechatGroupIds: [],
        },
      },
      wechatGroupMap: [],
      addWechatGroupMap: [],
      groupMap: [],
      landingPageGroups: [],
      groupAddForm: {},
      groupAddFormRules: {
        groupName: [
          { required: true, message: "请输入分组名称", trigger: "blur" },
        ],
      },
      templateMap: [],
      uploadCheckBox: true,
      wechatMap: [],
      statusMap: {
        0: "正式",
        1: "审核",
      },
      tempTemplateMap: {},
    };
  },
  mounted() {
    this.queryData();
    this.getWechatGroup();
    this.getTemplateLIst();
  },
  methods: {
    // 查询
    queryData() {
      this.$http
        .get("/system/landing/getPage", { params: this.queryForm })
        .then((response) => {
          if (response.data.code === 200) {
            this.tableData = response.data.data;
            this.total = response.data.count;
            this.tableData = this.tableData.map((v) => {
              v.conversionAmount =
                v.conversionAmount > 0 ? v.conversionAmount : 0;
              v.pageViewNums = v.pageViewNums > 0 ? v.pageViewNums : 0;
              v.templateId = v.templateId + "";
              return v;
            });
          }
        });
    },
    // 添加
    openAddPage() {
      this.uploadCheckBox = false;
      this.dialogAdd = true;
      this.getWechatGroup();
    },
    addPreservationFirst() {
      this.weChatForm = {
        wechatNumber: null,
      };
    },
    openEdit(e) {
      this.$http.get("/system/landing/getDetail?id=" + e).then((response) => {
        if (response.data.code) {
          response.data.data.amCarouselMode.type =
            response.data.data.amCarouselMode.type + "";
          let { landingPageGroups, ...res } = response.data.data;
          if (!res.weChatGroups) {
            res.weChatGroups = this.wechatGroupMap;
          }
          this.$set(this, "dictEditForm", res);
          // this.dictEditForm = res;
          // Object.assign(this.dictEditForm, res);
          this.landingPageGroups = landingPageGroups;
          this.dictEditForm.groupId = this.dictEditForm.groupId
            ? this.dictEditForm.groupId
            : null;

          this.dialogEdit = true;
          this.$forceUpdate();
        }
      });
    },
    addData() {
      this.$refs.dictAddForm.validate((valid) => {
        if (valid) {
          if (!this.dictAddForm.weChatGroups) {
            this.dictAddForm.weChatGroupIdList = this.addWechatGroupMap.reduce(
              (pre, v) => {
                if (v.ifChecked) {
                  pre.push(v.id);
                }
                return pre;
              },
              []
            );
          } else {
            this.dictAddForm.weChatGroupIdList =
              this.dictAddForm.weChatGroups &&
              this.dictAddForm.weChatGroups.reduce((pre, v) => {
                if (v.ifChecked) {
                  pre.push(v.id);
                }
                return pre;
              }, []);
          }
          if (
            !this.dictAddForm.weChatGroupIdList &&
            !this.dictAddForm.wechatNumber
          ) {
            this.$message({
              message: "请选择轮换切换!轮换切换是必选项",
              type: "error",
              duration: 2000,
            });
            return;
          }
          this.$http
            .post("/system/landing/add", this.dictAddForm)
            .then((response) => {
              if (response.data.code == 200) {
                this.dialogAdd = false;
                this.queryData();
                this.$message({
                  message: "添加落地页成功",
                  type: "success",
                  duration: 1000,
                });
                this.$refs.dictAddForm.resetFields();
                let templateMap = JSON.parse(
                  JSON.stringify(this.wechatGroupMap)
                );
                this.dictAddForm = {
                  rotation: "1",
                  fileUrl: "",
                  templateId: 1,
                  rotationParam: 0,
                  weChatGroupIdList: [],
                  wechatNumber: "",
                  landingPageName: "",
                };
                this.addWechatGroupMap = templateMap.map(
                  (v) => (v.ifChecked = false)
                );
              }
            });
        }
      });
    },
    editData() {
      let params = {
        basicCode: this.dictEditForm.basicCode,
        conversionCode: this.dictEditForm.conversionCode,
        fileUrl: this.dictEditForm.fileUrl,
        groupId: this.dictEditForm.groupId,
        id: this.dictEditForm.id,
        landingPageName: this.dictEditForm.landingPageName,
        remark: this.dictEditForm.remark,
        rotation: this.dictEditForm.amCarouselMode.typeStr * 1,
        rotationParam: this.dictEditForm.amCarouselMode.num,
        templateId: this.dictEditForm.templateId,
        wechatNumber: this.dictEditForm.amCarouselMode.modeWechat,
        weChatGroupIdList:
          this.dictEditForm.weChatGroups &&
          this.dictEditForm.weChatGroups.reduce((pre, v) => {
            if (v.ifChecked) {
              pre.push(v.id);
            }
            return pre;
          }, []),
      };
      this.$http.post("/system/landing/edit", params).then((response) => {
        if (response.data.code) {
          this.$message({
            message: "修改落地页成功",
            type: "success",
            duration: 2000,
          });
          this.uploadTemplate(params);
          this.queryData();
        }
      });
    },
    wechatNumberSave() {
      if (this.dictEditForm.amCarouselMode.modeWechat) {
        this.$refs.dictEditForm.validate((valid) => {
          if (valid) {
            this.editData();
          }
        });
      } else {
        this.$message({
          message: "微信号是必填",
          type: "error",
          duration: 2000,
        });
      }
    },
    wechatRotationSave() {
      let temp = this.dictEditForm.weChatGroups.reduce((pre, v) => {
        if (v.ifChecked) {
          pre.push(v.id);
        }
        return pre;
      }, []);
      if (this.dictEditForm.amCarouselMode.num !== "" && temp.length > 0) {
        this.$refs.dictEditForm.validate((valid) => {
          if (valid) {
            this.editData();
          }
        });
      } else {
        this.$message({
          message: "轮换设置及设置是必填",
          type: "error",
          duration: 2000,
        });
      }
    },
    fastsetupSave() {
      let temp =
        this.dictEditForm.weChatGroups &&
        this.dictEditForm.weChatGroups.reduce((pre, v) => {
          if (v.ifChecked) {
            pre.push(v.id);
          }
          return pre;
        }, []);
      if (this.dictEditForm.amCarouselMode.typeStr == 1) {
        if (this.dictEditForm.amCarouselMode.modeWechat) {
          this.$refs.dictEditForm.validate((valid) => {
            if (valid) {
              this.editData();
            }
          });
        } else {
          this.$message({
            message: "微信号是必填",
            type: "error",
            duration: 2000,
          });
        }
      } else {
        if (this.dictEditForm.amCarouselMode.num * 1 > 0 && temp.length > 0) {
          this.$refs.dictEditForm.validate((valid) => {
            if (valid) {
              this.editData();
            }
          });
        } else {
          this.$message({
            message: "必填项未填写",
            type: "error",
            duration: 2000,
          });
        }
      }
    },
    sumbitEdit() {
      this.$refs.dictEditForm.validate((valid) => {
        if (valid) {
          let tempWechatList =
            this.dictEditForm.weChatGroups &&
            this.dictEditForm.weChatGroups.reduce((pre, v) => {
              if (v.ifChecked) {
                pre.push(v.id);
              }
              return pre;
            }, []);
          if (!tempWechatList) {
            this.$message({
              message: "请选择轮换切换，轮换切换是必选项",
              type: "error",
              duration: 2000,
            });
            return;
          }
          let params = {
            basicCode: this.dictEditForm.basicCode,
            conversionCode: this.dictEditForm.conversionCode,
            fileUrl: this.dictEditForm.fileUrl,
            groupId: this.dictEditForm.groupId,
            id: this.dictEditForm.id,
            landingPageName: this.dictEditForm.landingPageName,
            remark: this.dictEditForm.remark,
            rotation: this.dictEditForm.amCarouselMode.typeStr * 1,
            rotationParam: this.dictEditForm.amCarouselMode.num,
            templateId: this.dictEditForm.templateId,
            weChatGroupIdList: tempWechatList,
            wechatNumber: this.dictEditForm.amCarouselMode.modeWechat,
          };
          this.$http.post("/system/landing/edit", params).then((response) => {
            if (response.data.code) {
              this.$message({
                message: "修改落地页成功",
                type: "success",
                duration: 2000,
              });

              this.queryData();
              this.dialogEdit = false;
            }
          });
        }
      });
    },
    changeCheckbox(id) {
      let temp = JSON.parse(JSON.stringify(this.dictEditForm.weChatGroups));
      this.dictEditForm.weChatGroups = temp.map((v) => {
        if (v.id == id) {
          v.ifChecked = !v.ifChecked;
        }
        return v;
      });
      this.$forceUpdate();
    },
    preservationSet() {},
    // 复制
    cloneEdit(e) {
      this.uploadCheckBox = false;
      this.dialogAdd = true;
      this.$http.get("/system/landing/getDetail?id=" + e).then((response) => {
        if (response.data.code) {
          response.data.data.amCarouselMode.type =
            response.data.data.amCarouselMode.type + "";
          let { landingPageGroups, ...res } = response.data.data;
          Object.assign(this.dictAddForm, res);
          this.dictAddForm.rotation = this.dictAddForm.amCarouselMode.typeStr;
          this.dictAddForm.rotationParam = this.dictAddForm.amCarouselMode.num;
          this.dictAddForm.landingPageName =
            this.dictAddForm.landingPageName + "-副本1";
          this.addWechatGroupMap = this.dictAddForm.weChatGroups
            ? this.dictAddForm.weChatGroups
            : JSON.parse(JSON.stringify(this.wechatGroupMap));
          this.uploadCheckBox = true;
          this.$forceUpdate();
        }
      });
    },
    handleAddClick() {
      // this.dictAddForm.weChatGroupIdList = [];
      // this.dictAddForm.wechatNumber = "";
      // this.dictAddForm.rotationParam = "";
    },
    upload(params) {
      let file = params.file;
      const isFormat =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif";
      const isSize = file.size / 1024 / 1024 < 30;
      if (!isFormat) {
        this.$message.error("上传图片只能是jpg、png、gif格式");
        return;
      }
      if (!isSize) {
        this.$message.error("上传图片大小不能超过30MB");
        return;
      }
      let form = new FormData();
      form.append("fileType", "1");
      form.append("files", file);
      this.$http.post("/common/file/upload", form).then((response) => {
        if (response.data.code === 200) {
          let data = response.data.data;
          if (data) {
            this.dictAddForm.fileUrl = data[0].url;
            this.$message({
              message: "添加图片成功",
              type: "success",
              duration: 2000,
            });
          }
        }
      });
    },
    deleteThisImg() {
      this.dictAddForm.fileUrl = "";
    },
    deleteThis(id) {
      this.$confirm("是否确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http
          .delete("/system/landing/delete?id=" + id)
          .then((response) => {
            // .then((response) => {
            if (response.data.code === 200) {
              this.$message.success("删除成功");
              this.queryData();
            }
          });
      });
    },
    getWechatGroup() {
      this.$http.get("/system/landing/getWeChatGroupList").then((response) => {
        if (response.data.code === 200) {
          this.wechatGroupMap = response.data.data;
          this.addWechatGroupMap = JSON.parse(
            JSON.stringify(this.wechatGroupMap)
          );
          this.uploadCheckBox = true;
        }
      });
    },
    changeWechatGroup(e) {
      this.addWechatGroupMap[e].ifChecked =
        !this.addWechatGroupMap[e].ifChecked;
    },
    addGroup() {
      let url;
      let str;
      if (this.groupAddForm.id) {
        url = "/system/landingGroup/edit";
        str = "编辑";
      } else {
        url = "/system/landingGroup/add";
        str = "添加";
      }
      this.$http
        .post(url, this.groupAddForm)
        .then((response) => {
          if (response.data.code === 200) {
            this.$message({
              message: str + "分组成功",
              type: "success",
              duration: 1000,
              onClose: () => {
                this.$router.push({ path: "/landing/group" });
              },
            });
            this.cleardialogAddGroup();
          }
        })
        .finally(() => {});
    },
    getLandingInfo(e) {
      if (e.conversionCode) {
        let url = [1, 2, 3, 4, 5, 6, 8, 9].includes(e.templateId * 1)
          ? "/system/template/getTemplateByLandingPageId"
          : "/system/template/getTemplateByLpi";
        this.$http
          .get(url, {
            params: {
              landingPageId: e.id,
            },
          })
          .then((response) => {
            if (response.data.code == 200) {
              window.open(response.data.data + "?isViewPre=1");
            }
          });
      } else {
        this.$confirm("转换代码未添加？", "提示", {
          confirmButtonText: "立即添加",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.openEdit(e.id);
        });
      }
    },
    getLandingLink(e) {
      if (e.conversionCode) {
        let url = [1, 2, 3, 4, 5, 6, 8, 9].includes(e.templateId * 1)
          ? "/system/template/getTemplateByLandingPageId"
          : "/system/template/getTemplateByLpi";
        this.$http
          .get(url, {
            params: {
              landingPageId: e.id,
            },
          })
          .then((response) => {
            if (response.data.code == 200) {
              let url = response.data.data;
              let oInput = document.createElement("input");
              oInput.value = url;
              document.body.appendChild(oInput);
              oInput.select(); // 选择对象;
              document.execCommand("Copy"); // 执行浏览器复制命令
              this.$message({
                message: "复制链接成功",
                type: "success",
                duration: 2000,
              });
              oInput.remove();
            }
          });
      } else {
        this.$confirm("转换代码未添加？", "提示", {
          confirmButtonText: "立即添加",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.openEdit(e.id);
        });
      }
    },
    getTemplateLIst() {
      this.$http.get("/system/template/getTemplateList").then((response) => {
        if (response.data.code == 200) {
          this.templateMap = response.data.data;
          this.tempTemplateMap = this.templateMap.reduce((pre, v) => {
            pre[v.id] = v.templateName;
            return pre;
          }, {});
        }
      });
    },
    clearLandingForm() {
      this.dialogEdit = false;
      this.$refs.dictEditForm.resetFields();
    },
    cleardialogAddGroup() {
      this.dialogAddGroup = false;
      this.$refs.groupAddForm.resetFields();
    },
    clearAddForm() {
      this.dialogAdd = false;
      this.$refs.dictAddForm.resetFields();
      this.dictAddForm = {
        rotation: "1",
        fileUrl: "",
        templateId: 1,
        rotationParam: 0,
        weChatGroupIdList: [],
      };
    },
    openAddGroup() {
      this.dialogAddGroup = true;
    },
    goDetail(e) {
      this.$router.push({
        name: "StatisticsDetail",
        params: { type: 3, name: e.groupName },
      });
    },
    debounce(fn, delay) {
      let timer = null; //借助闭包
      return function () {
        if (timer) {
          clearTimeout(timer); //进入该分支语句，说明当前正在一个计时过程中，并且又触发了相同事件。所以要取消当前的计时，重新开始计时
          timer = setTimeout(fn, delay);
        } else {
          timer = setTimeout(fn, delay); // 进入该分支说明当前并没有在计时，那么就开始一个计时
        }
      };
    },
    getWechatList(e) {
      if (e !== "") {
        let params = {
          groupName: "",
          pageNo: "",
          pageSize: "",
          wechatNumber: e,
        };
        this.$http
          .get("/system/weChat/getListByWeChatNumber", { params })
          .then((response) => {
            if (response.data.code == 200) {
              this.wechatMap = response.data.data;
            }
          });
      }
    },
    editGetWechatMap(e) {
      if (e !== "") {
        this.debounce(this.getWechatList(e), 1000);
      }
    },
    getWechatMap(e) {
      if (e !== "") {
        this.debounce(this.getWechatList(e), 1000);
      }
    },
    changeTemplateStatus(e) {
      let temp = {
        id: e.id,
        status: e.status ? 0 : 1,
      };
      this.editTemlate(temp);
    },
    changeTemplate(e) {
      let temp = {
        id: e.id,
        templateId: e.templateId,
      };
      this.editTemlate(temp);
    },
    editTemlate(e) {
      this.$http
        .post("/system/landing/editStatusOrTemplate", e)
        .then((response) => {
          if (response.data.code == 200) {
            this.$message.success("修改成功");
            this.uploadTemplate(e);
            this.queryData();
          }
        });
    },
    uploadTemplate(e) {
      let url = [1, 2, 3, 4, 5, 6, 8, 9].includes(e.templateId * 1)
        ? "/system/template/getTemplateByLandingPageId"
        : "/system/template/getTemplateByLpi";
      this.$http
        .get(url, {
          params: {
            landingPageId: e.id,
          },
        })
        .then((response) => {
          if (response.data.code == 200) {
          }
        });
    },
  },
};
</script>
<style scoped>
.el-tabs.el-tabs--top {
  min-height: 200px;
}
.img-uploader {
  border: 1px dashed #000;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 150px;
  height: 150px;
}
.img-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 136px;
  height: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.image {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  max-width: 300px;
  max-height: 300px;
  min-width: 150px;
  min-height: 150px;
}
.imgbox {
  width: 174px;
  height: 144px;
  position: relative;
}
.upload-img {
  width: 174px;
  height: 144px;
}
.imgbox:hover .img-action {
  display: block;
}
.img-action {
  display: none;
  background: #000;
  position: absolute;
  top: 0;
  right: 0;
  /* height: 50px; */
  width: 174px;
  opacity: 0.7;
  text-align: center;
}
.delete-btn {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}
/* .el-form--inline .el-form-item{
  display: block;
} */
.el-checkbox-group {
  line-height: 30px;
  margin-top: 20px;
}
.base-set-up,
.fast-set-up {
  /* display: flex;
  align-items: center;
  justify-content: space-around; */
}
.checkbox-content {
  padding: 10px 0;
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #fff;
  background-color: #409eff;
}
.el-tag--small {
  cursor: pointer;
}
</style>
